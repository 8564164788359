/**
 *
 * @param nosfield
 * @constructor
 */
function sqlSearch(nosfield){
	/**
	 * @type {nosfield2}
	 */
	this.nosfield = nosfield;
	/**
	 * @type {?string}
	 */
	this.currentSearchId = null;
	/**
	 * @type {number}
	 */
	this.page = 1;
	/**
	 * @type {?string}
	 */
	this.lastSearchString = null;
	/**
	 * @type {object[]}
	 */
	this.cache = [];
	
	this.lastSearchTime = 0;
	this.last = null;
}

/**
 * Initialisierung des Objects
 * @public
 */
sqlSearch.prototype.init = function(){
	setTimeout(() => {
		if(this.nosfield.options.selectedId){
			const skip = this.nosfield.skipDoAfter;
			if(this.nosfield.options.selectedIdSkipDoAfter){
				this.nosfield.skipDoAfter = true;
			}
			this.nosfield.renderer.append(this.nosfield.options.selectedId);
			this.nosfield.selector.selectFirst().getSelectedEl().click();
			this.nosfield.renderer.clearView();
			
			if(this.nosfield.options.selectedIdSkipDoAfter){
				this.nosfield.skipDoAfter = skip;
			}
		}else{
			if(this.nosfield.options.selectedIds){
				const skip = this.nosfield.skipDoAfter;
				if(this.nosfield.options.selectedIdSkipDoAfter){
					this.nosfield.skipDoAfter = true;
				}
				this.nosfield.options.selectedIds.forEach(selectedId => {
					this.nosfield.renderer.append(selectedId);
					this.nosfield.selector.selectFirst().getSelectedEl().click();
					this.nosfield.renderer.clearView();
				});
				
				
				if(this.nosfield.options.selectedIdSkipDoAfter){
					this.nosfield.skipDoAfter = skip;
				}
			}
		}
	});
};

/**
 * Führt die eigentliche Suche aus
 * @private
 * @param {boolean} silent - soll der Abdunkler genutzt werden
 * @param {function} callback
 * @param {boolean}preloading
 * @param {boolean} force
 */
sqlSearch.prototype.genericSearch = function(silent, callback, preloading, force = false){
	if(true || !this.nosfield.searching){
		const searchString = this.nosfield.util.normalize(this.nosfield.elements.view.value.trim());
		
		if(searchString.length < this.nosfield.options.minSearchTermLength){
			return;
		}
		
		if(!preloading && (!force && this.lastSearchString === searchString)){
			return;
		}
		
		this.lastSearchString = searchString;
		this.nosfield.searching = true;
		const searchId = this.generateSearchId();
		this.currentSearchId = searchId;
		
		const tmp_ajax_object = {
			id: this.nosfield.id,
			info_db_now: this.nosfield.options.info_db_now,
			search_fields: this.nosfield.options.searchFields,
			search_word: searchString,
			search_id: searchId,
			page: this.page,
			page_length: this.nosfield.options.pageLength,
			field_to_set: this.nosfield.options.fieldToSet,
			restrictions: this.buildRestrictions(),
			replaceFields: this.nosfield.options.replaceFields,
		};
		if(this.nosfield.options.noRelevance){
			tmp_ajax_object.no_relevance = true;
		}
		const currentSearchTime = new Date().getTime();
		this.lastSearchTime = currentSearchTime;
		
		this.last = ajaxcom({
			'm': 'erp_sys',
			's': 'interface',
			'a': 'nosfield2_search',
			'q': tmp_ajax_object,
			'without_wait': silent,
			'hf': {
				'success': (result) => {
					if(this.lastSearchTime === currentSearchTime){
						this.page++;
						if(result.result.length !== this.nosfield.options.pageLength){
							this.nosfield.continueSearch = false;
						}
						this.nosfield.searching = false;
						
						if(callback){
							callback(result.result);
						}
					}
				},
				'error': (msg) => {
					this.nosfield.continueSearch = false;
					this.nosfield.searching = false;
					erp.msg(msg, 'error');
				},
			},
		});
		
	}
};

/**
 * Suche nachdem etwas geschrieben wurde
 * @param {boolean} force
 * @public
 */
sqlSearch.prototype.search = function(force = false){
	this.nosfield.continueSearch = true;
	this.page = 1;
	this.genericSearch(false, (result) => {
		this.cache = [];
		if(result){//auch bei leerem ergebniss
			this.nosfield.renderer.replaceView(result);
			this.nosfield.renderer.showResult();
		}
	}, false, force);
};

/**
 * Läd weitere Datensätze nach bzw. holt sie aus dem Cache
 * @public
 */
sqlSearch.prototype.preload = function(){
	if(this.cache.length){
		this.nosfield.renderer.appendRows(this.cache);
		this.cache = [];
	}else{
		if(this.nosfield.continueSearch && !this.nosfield.searching){
			this.genericSearch(false, (result) => {
				if(result.length){
					this.nosfield.renderer.appendRows(result);
				}else{
					this.nosfield.continueSearch = false;
				}
			}, true);
		}
	}
};

/**
 * Befüllt den Cache mit weiteren Suchtreffern
 * @public
 */
sqlSearch.prototype.silentPreload = function(){
	if(!this.cache.length && this.nosfield.continueSearch && !this.nosfield.searching){
		this.nosfield.logger.log('info', 'silentPreload Page {page}', {
			page: this.page,
		});
		this.genericSearch(true, (result) => {
			if(result.length){
				this.cache = result;
				this.nosfield.renderer.preloadHandler();
			}else{
				this.nosfield.continueSearch = false;
			}
		}, true);
	}
};

/**
 * Generiert eine zufällige Id
 * @private
 * @returns {string}
 */
sqlSearch.prototype.generateSearchId = function(){
	return Math.floor(Math.random() * 1000000).toString();
};

/**
 * Holt abhängigkeiten zu anderen Nosfields
 * @private
 */
sqlSearch.prototype.buildRestrictions = function(){
	const restrictions = this.nosfield.connector.getRestrictions();
	
	const result = [];
	
	restrictions.forEach(obj => {
		if(typeof obj.restriction === 'string' && obj.value !== ''){
			result.push(btoa(obj.restriction.replace('[value]', obj.value)));
		}
	});
	
	return result;
};

/**
 * @public
 * @returns {sqlSearch}
 */
sqlSearch.prototype.resetLastSearchString = function(){
	this.lastSearchString = '';
	return this;
};