/**
 * nosfield2 multiSelectRenderer Konstruktor
 * @constructor
 * @param {nosfield2} nosfield
 */
function multiSelectRenderer(nosfield){
	/**
	 * @type {nosfield2}
	 */
	this.nosfield = nosfield;
	/**
	 * @type {object|null}
	 */
	this.result = null;
	/**
	 * @type {boolean}
	 */
	this.resultVisible = true;
	if(Intl && Intl.NumberFormat){
		/**
		 * @type {Intl.NumberFormat}
		 */
		this.numberFormatter = new Intl.NumberFormat(this.nosfield.options.numberFormatLocale, {
			style: 'decimal',
			useGrouping: true,
			minimumFractionDigits: this.nosfield.options.numberFormatDecimals,
			maximumFractionDigits: this.nosfield.options.numberFormatDecimals,
		});
	}else{
		/**
		 * @type {{format: (function(*): *)}}
		 */
		this.numberFormatter = {
			/**
			 * @param {*} a
			 * @returns {*}
			 */
			format: a => a,
		};
	}
	/**
	 * @type {number}
	 */
	this.fieldCounter = 0;
}

/**
 * Initialisation - erstellt result Table und wheel event
 * @public
 */
multiSelectRenderer.prototype.init = function(){
	//init result table
	const el = document.createElement('table');
	el.className = 'nosfieldResultTable';
	el.style.width = '100%';
	this.result = el;
	el.addEventListener('wheel', (evt) => {
		if(this.nosfield.continueSearch && !this.nosfield.searchComponent.cache.length){
			this.nosfield.searchComponent.silentPreload();
		}
		
		const list = el.querySelectorAll('tr');
		const last = list[list.length - 1];
		
		// noinspection JSUnresolvedFunction
		const tablePos = el.parentNode.getBoundingClientRect();
		const lastPos = last.getBoundingClientRect();
		
		
		const top = tablePos.top + tablePos.height + (1.5 * lastPos.height);
		
		if(evt.deltaY > 0 && lastPos.top < top){
			this.nosfield.searchComponent.preload();
		}
	});
	if(this.nosfield.options.resultTarget){
		this.nosfield.elements.result.remove();
		this.nosfield.elements.result = document.querySelector(this.nosfield.options.resultTarget);
		//this.nosfield.elements.result.style['overflow-y'] = 'scroll';
	}else{
		throw 'multiSelectRenderer braucht ein Target Element';
	}
	
	this.nosfield.elements.result.appendChild(el);
};

/**
 * baut den Header anhand eines Arrays auf
 * @param {object[]|string} header
 * @private
 * @return {string}
 */
multiSelectRenderer.buildHeader = function(header){
	return '';
};

/**
 * Leert das Ergebniss
 * @public
 * @return {multiSelectRenderer}
 */
multiSelectRenderer.prototype.clearView = function(){
	if(this.nosfield.options.header){
		if(typeof this.nosfield.options.header == 'string'){
			this.result.innerHTML = this.nosfield.options.header;
		}else{
			this.result.innerHTML = this.buildHeader(this.nosfield.options.header);
		}
	}else{
		this.result.innerHTML = '';
	}
	this.fieldCounter = 0;
	this.nosfield.event.emit('viewCleared');
	this.showResult();
	return this;
};

/**
 * Fügt eine Zeile ans Ergebniss an
 * @private
 * @param {object} row
 * @return {object} - das erzeugte HTML Element
 */
multiSelectRenderer.prototype.append = function(row){
	const el = this.createElement(row);
	this.result.appendChild(el);
	
	return el;
};

/**
 * Fügt mehrere Zeile ans Ergebniss an
 * @public
 * @param {object[]} rows
 * @return {multiSelectRenderer}
 */
multiSelectRenderer.prototype.appendRows = function(rows){
	for(let i in rows){
		this.append(rows[i]);
	}
	return this;
};

/**
 * Ersetzt die View komplett
 * @public
 * @param {object[]} elements
 * @return {multiSelectRenderer}
 */
multiSelectRenderer.prototype.replaceView = function(elements){
	this.clearView();
	
	for(let i in elements){
		this.append(elements[i]);
	}
	this.nosfield.selector.selectFirst();
	return this;
};

/**
 * Erstellt eine ein tr Element für das Ergebniss
 * @private
 * @param {object} row
 * @return {object} - Das tr Element
 */
multiSelectRenderer.prototype.createElement = function(row){
	const tr = document.createElement('tr');
	tr.className = `xRow ${this.nosfield.options.rowClass}`;
	tr.style.cursor = 'default';
	
	const checkbox = document.createElement('input');
	checkbox.type = 'checkbox';
	checkbox.name = `${this.nosfield.options.fieldName}[]`;
	checkbox.value = row[this.nosfield.options.fieldToSet];
	
	checkbox.onchange = (evt) => {
		if(evt.target.checked && !this.nosfield.skipDoAfter){
			this.nosfield.options.doAfter(this.nosfield, evt.target.dataset.id);
		}
	};
	
	checkbox.id = `${this.nosfield.id}_checkbox_${this.fieldCounter}`;
	
	this.fieldCounter++;
	
	const checkboxTd = document.createElement('td');
	checkboxTd.className = this.nosfield.options.cellClass;
	
	checkboxTd.appendChild(checkbox);
	tr.appendChild(checkboxTd);
	
	
	let displayFields = this.nosfield.options.displayFields;
	if(displayFields.length === 0){
		displayFields = Object.keys(row);
	}
	displayFields.forEach((i) => {
		if(i === this.nosfield.options.fieldToSet && this.nosfield.options.displayFields.indexOf(i) === -1){
			return;
		}
		const td = document.createElement('td');
		td.className = this.nosfield.options.cellClass;
		td.dataset.field_name = i.toString();
		
		const label = document.createElement('label');
		label.htmlFor = checkbox.id;
		td.appendChild(label);
		//wenn in options ein feldtyp definiert ist gibt es eine gesondertes Rendering
		if(this.nosfield.options.fieldKinds[i] !== undefined){
			this.renderFieldKind(label, this.nosfield.options.fieldKinds[i], row[i], row);
		}else{
			label.innerText = row[i];
		}
		
		const conditionalStyle = this.nosfield.conditionalStyle.checkRow(row);
		let styleObj = {};
		for(let k = 0; k < conditionalStyle.length; k++){
			styleObj = conditionalStyle[k];
			if(typeof (styleObj) === 'string'){
				tr.classList.add(styleObj);
			}else{
				for(let j in styleObj){
					td.style[j] = styleObj[j];
				}
			}
		}
		
		
		tr.appendChild(td);
	});
	
	tr.dataset.nosfieldId = this.nosfield.id;
	tr.dataset.id = row[this.nosfield.options.fieldToSet];
	
	tr.onclick = function(evt){
		// noinspection JSUnresolvedFunction
		const checkbox = evt.target.querySelector('input');
		if(checkbox){
			checkbox.checked = !checkbox.checked;
		}
	};
	
	tr.onmouseover = (evt) => {
		this.nosfield.selector.selectEl(evt.target);
	};
	
	this.nosfield.event.emit('elementCreated', {el: tr,}, true);
	
	return tr;
};

/**
 * Befüllt ein spezial td mit Daten wenn es in nosfield.options.fieldKinds definiert ist
 * @private
 * @param {object} el - das td Element
 * @param {object} data - der eintrag in nosfield.options.fieldKinds
 * @param {string} content - der Inhalt des Feldes
 * @param {object} row - Das Datenobject der Zeile
 * @return {object} - das td Element
 */
multiSelectRenderer.prototype.renderFieldKind = function(el, data, content, row){
	data.options = data.options || {};
	switch(data.kind){
		case 'img':
		case 'image':
			const img = document.createElement('img');
			img.src = content;
			img.style.width = data.options.width || 'auto';
			img.style.height = data.options.height || 'auto';
			
			el.appendChild(img);
			break;
		
		case 'a':
		case 'link':
			const a = document.createElement('a');
			let href = data.options.href || content;
			
			a.innerText = content;
			
			for(let i in row){
				href = href.replace(`[replace][${i}]`, row[i]);
			}
			
			a.href = href;
			a.target = '_blank';
			
			a.onclick = function(evt){
				//verhindert, dass zeile ausgewählt wird
				evt.stopPropagation();
			};
			
			el.appendChild(a);
			break;
		
		case 'float':
		case 'double':
			const newValue = parseFloat(content);
			if(!isNaN(newValue)){
				content = newValue.toString();
			}
			
			el.innerText = this.numberFormatter.format(content);
			break;
	}
	
	return el;
};

/**
 * Dummy - ist immer sichtbar
 * @public
 */
multiSelectRenderer.prototype.hideResult = function(){
	//da sonst der escapehandler kaputt ist wird hiermit der fokus auf dem input zerstört
	//this.nosfield.elements.view.blur();
};

/**
 * wird missbraucht um die Höhe zu prüfen und dementspechen scroll zu aktivieren
 * @public
 */
multiSelectRenderer.prototype.showResult = function(){
	const resultRect = this.result.getBoundingClientRect();
	const resultParentRect = this.result.parentNode.getBoundingClientRect();
	
	if(resultRect.height > resultParentRect.height){
		this.result.parentNode.style['overflow-y'] = 'scroll';
	}else{
		this.result.parentNode.style['overflow-y'] = 'visible';
	}
};

/**
 * Gibt zurück ob das Ergebniss sichtbar ist
 *	da immer sichtbar gibt es immer true zurück
 * @public
 * @return {boolean}
 */
multiSelectRenderer.prototype.resultIsVisible = function(){
	return true;
};

/**
 * Dummy - position durch externes div schon gegeben
 * @public
 */
multiSelectRenderer.prototype.setResultPosition = function(){
	
};

/**
 * Führt eine (silent-)Preload aus wenn nötig
 * @public
 */
multiSelectRenderer.prototype.preloadHandler = function(){
	const list = this.nosfield.util.toArray(this.result.children);
	const selected = this.nosfield.selector.getSelectedEl();
	
	const index = list.indexOf(selected);
	if(index > (list.length - (this.nosfield.options.pageLength / 2))){
		this.nosfield.searchComponent.silentPreload();
	}
	if(index > (list.length - 5)){
		this.nosfield.searchComponent.preload();
	}
};

/**
 * @param {HTMLElement} el
 * @returns {string}
 */
multiSelectRenderer.prototype.getViewResult = function(el){
	let view = '';
	const tds = el.querySelectorAll('td');
	for(let i = 0; i < tds.length; i++){
		if(tds[i].innerText){
			view += ' ' + tds[i].innerText;
		}
	}
	
	return view.trim();
};
