/**
 * nosfield2 defaultOptions Konstruktor
 * @constructor
 * @param {nosfield2} nosfield
 * @param {object} options
 */
function defaultOptions(nosfield, options) {
    /**
     * @type {nosfield2}
     */
    this.nosfield = nosfield;

    
    this.noEnter = false;
    
    /**
     *
     * @type {{numberFormatLocale: ((function(string): boolean)|*), pageLength: *, pageSkip: *, numberFormatDecimals: *, restrictions: *, searchTimeout: *}}
     */
    this.mergeTests = {
        searchTimeout: this.nosfield.util.isInt,
        pageLength: this.nosfield.util.isInt,
        pageSkip: this.nosfield.util.isInt,
        numberFormatDecimals: this.nosfield.util.isInt,
        numberFormatLocale: this.nosfield.util.isNumberFormatLocale,
        restrictions: Array.isArray,
        buttons: Array.isArray,
    };
    /**
     * @type {boolean}
     */
    this.debug = false;

    /**
     * @type {Function}
     */
    this.renderer = tableRenderer;

    /**
     * @type {Function}
     */
    this.resultRenderer = ResultRendererInput;
    
    /**
     * @type {Boolean}
     */
    this.hideMultiselectStyleChange = false;

    /**
     * @type {Function}
     */
    this.search = sqlSearch;
    /**
     * @type {Function}
     */
    this.keyHandler = keyHandler;
    /**
     * @type {Function}
     */
    this.connector = connector;
    /**
     * @type {number}
     */
    this.minSearchTermLength = 3;

    this.initialValueSearch = true;

    /*
     * ResultRenderer
     */
    this.resultRendererViewBorder = '';
    /*
     * Clear button
     */
    /**
     * @type {boolean}
     */
    this.renderClearButton = true;
    /**
     * @type {boolean}
     */
    this.materialIcons = false;

    /**
     * @type {string}
     */
    this.clearButtonWarning = 'glyphicon glyphicon-warning-sign';
    this.clearButtonWarningColor = '#FF5C5C';
    this.clearButtonWarningText = ew('info_no_selected');
    /**
     * @type {string}
     */
    this.clearButtonClear = 'glyphicon glyphicon-minus-sign';
    this.clearButtonClearColor = '#000';
    this.clearButtonClearText = ew('remove');
    /**
     * Scrollverhalten
     */
    /**
     * @type {string}
     */
    this.scrollBehaviour = 'auto';//'smooth';
    /**
     * @type {string}
     */
    this.scrollBlock = 'nearest';//'start';//'end';

    /**
     * @type {number}
     */
    this.searchTimeout = 250;

    /**
     * @type {number}
     */
    this.pageLength = 30;
    /**
     * @type {boolean}
     */
    this.required = false;

    /**
     * skip-faktor für pageUp pageDown keyhandler
     * @type {number}
     */
    this.pageSkip = 15;
    /**
     * @type {string}
     */
    this.headerRowClass = 'nosfieldHeaderRow';
    /**
     * @type {string}
     */
    this.rowClass = 'nosfieldRow';
    /**
     * @type {string}
     */
    this.cellClass = 'nosfieldCell';
    /**
     *
     * @type {string}
     */
    this.selectedRowClass = 'nosfieldSelectedRow';

    this.relatedHighlightClass = 'nosfieldRelatedNosfield';

    /**
     * @type {boolean}
     */
    this.noRelevance = false;

    /**
     * @type {string[]}
     */
    this.searchFields = [];
    /**
     * @type {string}
     */
    this.info_db_now = '';
    /**
     * @type {{}[]}
     */
    this.replaceFields = [];

    /**
     * @type {object|null}
     */
    this.searchObj = null;

    /**
     *
     * @param {object[]} searchResult
     * @param {nosfield} nosfield
     * @returns {object[]}
     */
    this.sortFunction = (searchResult, nosfield) => searchResult;

    /**
     * @type {boolean}
     */
    this.useSessionStorage = false;
    /**
     *
     * @type {string}
     */
    this.sessionStorageKey = '';

    /**
     * @type {object[]}
     */
    this.restrictions = [];

    /**
     * @type {{}}
     */
    this.restrictionMap = {};

    /**
     * @type {string[]}
     */
    this.displayFields = [];
    /**
     * @type {string}
     */
    this.fieldToSet = 'id';
    /**
     * @type {string}
     */
    this.fieldName = '';
    /**
     * @type {string|object}
     */
    this.header = '';

    /**
     * div in dem das ergebniss angezeigt werden soll
     * @type {boolean|string}
     */
    this.resultTarget = false;
    /**
     * @type {boolean}
     */
    this.fullWidth = false;

    /**
     * @type {number}
     */
    this.maxHeight = 400;

    this.forceWidthToInput = false;


    /**
     * mindestabstand zu Ober- oder Unterkannte des Browsers in px
     * @type {number}
     */
    this.resultVMargin = 10;

    /**
     * @type {boolean}
     */
    this.forceResultBelow = false;

    /**
     * @type {number}
     */
    this.numberFormatDecimals = 2;
    /**
     * @type {string}
     */
    this.numberFormatLocale = 'de-DE';

    /**
     *
     * @type {{}}
     */
    this.fieldKinds = {};
    /**
     * @type {object[]}
     */
    this.styleConditions = [];

    /**
     * @type {string}
     */
    this.selectedId = '';
    /**
     * @type {[string]}
     */
    this.selectedIds = [];

    /**
     * @type {boolean}
     */
    this.selectedIdSkipDoAfter = false;

    this.disabled = false;

    this.events = [];

    this.lang = '23232323';

    /*
    * Callbacks
    */
    /**
     * @type {function}
     */
    this.onReady = function (nosfield) {
    };

    /**
     * @type {function}
     */
    this.doAfter = function (nosfield, selected_id) {
    };
    /**
     * @type {function}
     */
    this.onClear = function (nosfield) {
    };
    /**
     * @type {function}
     */
    this.onKeyDown = function (nosfield, evt) {
    };
    /**
     * @type {function}
     */
    this.onKeyUp = function (nosfield, evt) {
    };
    /**
     * @type {function}
     */
    this.onSelect = function (nosfield, selectedId) {
    };
    /**
     * @type {function}
     */
    this.onDeselect = function (nosfield, deselectedId) {
    };

    this.onClosedByClick = function (nosfield) {
    };

    this.buttons = [];

    this.modifyView = (view) => view;

    this.restClient = null;

    this.searchClass = '';

    this.merge(options);
}

/**
 * Kombiniert die Defaultoptions mit der Übergabe
 * @private
 * @param {object} options
 */
defaultOptions.prototype.merge = function (options) {
    for (let i in options) {
        if (i === 'mergeTests') {
            continue;
        }
        if (this[i] !== undefined) {
            if (this.mergeTests[i]) {
                if (this.mergeTests[i](options[i])) {
                    this[i] = options[i];
                } else {
                    console.error('Nosfield2 invalid option: ' + i + ' - value:');
                    console.error(options[i]);
                }
            } else {
                this[i] = options[i];
            }
        }
    }
};