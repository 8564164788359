/**
 * nosfield2 listRenderer Konstruktor
 * @constructor
 * @param {nosfield2} nosfield
 */
function listRenderer(nosfield){
	/**
	 * @type {nosfield2}
	 */
	this.nosfield = nosfield;
	/**
	 *
	 * @type {object|null}
	 */
	this.result = null;
	/**
	 * @type {boolean}
	 */
	this.resultVisible = false;
	if(Intl && Intl.NumberFormat){
		/**
		 * @type {Intl.NumberFormat}
		 */
		this.numberFormatter = new Intl.NumberFormat(this.nosfield.options.numberFormatLocale, {
			style: 'decimal',
			useGrouping: true,
			minimumFractionDigits: this.nosfield.options.numberFormatDecimals,
			maximumFractionDigits: this.nosfield.options.numberFormatDecimals,
		});
	}else{
		/**
		 * @type {{format: (function(*): *)}}
		 */
		this.numberFormatter = {
			format: a => a,
		};
	}
}

/**
 * Initialisation - erstellt result Table und wheel event
 * @public
 */
listRenderer.prototype.init = function(){
	//init result table
	const el = document.createElement('ul');
	el.className = 'nosfieldResultTable';
	el.style.listStyle = 'none';
	el.style.marginBottom = '0px';
	
	this.result = el;
	/*
	el.addEventListener('wheel', (evt) => {
		if(this.nosfield.continueSearch && !this.nosfield.searchComponent.cache.length){
			this.nosfield.searchComponent.silentPreload();
		}

		const list = el.querySelectorAll('tr');
		const last = list[list.length - 1];

		// noinspection JSUnresolvedFunction
		const tablePos = el.parentNode.getBoundingClientRect();
		const lastPos = last.getBoundingClientRect();


		const top = tablePos.top + tablePos.height + (1.5 * lastPos.height);

		if(evt.deltaY > 0 && lastPos.top < top){
			this.nosfield.searchComponent.preload();
		}
	});
	*/
	
	this.nosfield.elements.result.appendChild(el);
	
};

/**
 * Leert das Ergebniss
 * @public
 * @return {listRenderer}
 */
listRenderer.prototype.clearView = function(){
	this.result.innerHTML = '';
	this.nosfield.event.emit('viewCleared');
	return this;
};

/**
 * Fügt eine Zeile ans Ergebniss an
 * @private
 * @param {object} row
 * @return {object} - das erzeugte HTML Element
 */
listRenderer.prototype.append = function(row){
	const el = this.createElement(row);
	this.result.appendChild(el);
	
	return el;
};

/**
 * Fügt mehrere Zeile ans Ergebniss an
 * @public
 * @param {object[]} rows
 * @return {listRenderer}
 */
listRenderer.prototype.appendRows = function(rows){
	for(let i in rows){
		this.append(rows[i]);
	}
	return this;
};

/**
 * Ersetzt die View komplett - behält Selektion bei wenn möglich
 * @public
 * @param {object[]} elements
 * @return {listRenderer}
 */
listRenderer.prototype.replaceView = function(elements){
	this.clearView();
	
	this.appendRows(elements);
	
	this.nosfield.selector.selectFirst();
	setTimeout(() => {
		this.result.parentNode.scrollTo(0, 0);
	});
	
	this.nosfield.event.emit('viewReplaced');
	return this;
};

/**
 * Erstellt eine ein tr Element für das Ergebniss
 * @private
 * @param {object} row
 * @return {object} - Das tr Element
 */
listRenderer.prototype.createElement = function(row){
	const li = document.createElement('li');
	li.className = `xRow ${this.nosfield.options.rowClass}`;
	
	let displayFields = this.nosfield.options.displayFields;
	if(displayFields.length === 0){
		displayFields = Object.keys(row);
	}
	
	const conditionalStyle = this.nosfield.conditionalStyle.checkRow(row);
	displayFields.forEach((i) => {
		if(i === this.nosfield.options.fieldToSet && this.nosfield.options.displayFields.indexOf(i) === -1){
			return;
		}
		
		li.dataset.field_name = i.toString();
		
		if(this.nosfield.options.fieldKinds[i] !== undefined){
			this.renderFieldKind(li, this.nosfield.options.fieldKinds[i], row[i], row);
		}else{
			li.innerText = row[i];
		}
	});
	
	conditionalStyle.forEach((styleObj) => {
		if(typeof (styleObj) === 'string'){
			li.classList.add(styleObj);
		}else{
			for(let i in styleObj){
				li.style[i] = styleObj[i];
			}
		}
	});
	li.style.cursor = 'pointer';
	li.dataset.nosfieldId = this.nosfield.id;
	li.dataset.id = row[this.nosfield.options.fieldToSet];
	
	li.addEventListener('click', (evt) => {
		this.nosfield.handleClick(evt);
	});
	
	li.onmouseover = (evt) => {
		this.nosfield.selector.selectEl(evt.target);
	};
	
	this.nosfield.event.emit('elementCreated', {el: li,}, true);
	
	return li;
};

/**
 * Befüllt ein spezial td mit Daten wenn es in nosfield.options.fieldKinds definiert ist
 * @private
 * @param {HTMLElement} el - das td Element
 * @param {object} data - der eintrag in nosfield.options.fieldKinds
 * @param {string} content - der Inhalt des Feldes
 * @param {object} row - Das Datenobject der Zeile
 * @return {object} - das td Element
 */
listRenderer.prototype.renderFieldKind = function(el, data, content, row){
	data.options = data.options || {};
	switch(data.kind){
		case 'html':
			el.innerHTML = content;
			break;
		
		case 'img':
		case 'image':
			const img = document.createElement('img');
			img.src = content;
			img.style.width = data.options.width || 'auto';
			img.style.height = data.options.height || 'auto';
			
			el.appendChild(img);
			break;
		
		case 'a':
			const a = document.createElement('a');
			let href = data.options.href || content;
			
			a.innerText = content;
			
			for(let i in row){
				href = href.replace(`[replace][${i}]`, row[i]);
			}
			
			a.href = href;
			a.target = '_blank';
			
			a.onclick = function(evt){
				//verhindert, dass zeile ausgewählt wird
				evt.stopPropagation();
			};
			
			el.appendChild(a);
			break;
		
		case 'float':
		case 'double':
			const newValue = parseFloat(content);
			if(!isNaN(newValue)){
				content = newValue.toString();
			}
			
			el.innerText = this.numberFormatter.format(content);
			break;
		case 'callback':
			el.innerHTML = content;
			if(data.options.field && row[data.options.field]){
				const funcReg = new RegExp('\\({1}[^\\)]*\\)[;]*');
				const paramReg = new RegExp('\\(([^\\)]*)\\)');
				let funcArray = row[data.options.field].toString().replace(funcReg, '').split('.');
				const paramMatch = row[data.options.field].toString().match(paramReg);
				if(paramMatch === null){
					if(row[data.options.field].toString().substr(0, 1) === '?'){
						(function(value){
							el.addEventListener('click', (evt) => {
								window.location = value;
							});
						})(row[data.options.field].toString());
						
					}
					return el;
				}
				const paramArray = paramMatch[1]
					.replace(/"/g, '')
					.replace(/'/g, '')
					.split(',');
				
				const newFuncArray = [];
				funcArray.forEach(function(val){
					const index = val.indexOf('[');
					if(index < 0){
						newFuncArray.push(val);
					}else{
						newFuncArray.push(val.substr(0, index));
						newFuncArray.push(val.substr(index + 1).replace(']', '').replace(/"/g, '').replace(/'/g, ''));
					}
				});
				
				funcArray = newFuncArray;
				
				let func = window;
				let baseObj = window;
				const preLast = funcArray.length - 1;
				if(funcArray.length > 1){
					for(let i = 0; i < funcArray.length; i++){
						if(func[funcArray[i]]){
							func = func[funcArray[i]];
							if(i === preLast){
								baseObj = func;
							}
						}else{
							func = null;
							break;
						}
					}
				}else{
					if(funcArray.length === 1 && window[funcArray[0]]){
						func = window[funcArray[0]];
					}
				}
				el.dataset.org_call = funcArray.join('.');
				((func, params, base) => {
					if(func){
						el.addEventListener('click', (evt) => {
							func.apply(base, params);
						});
					}
				})(func, paramArray, baseObj);
				
			}
			break;
	}
	
	return el;
};

/**
 * Versteckt die Ergebnissanzeige
 * @public
 */
listRenderer.prototype.hideResult = function(){
	this.nosfield.elements.result.style.display = 'none';
	this.nosfield.elements.result.style.position = 'relative';
	this.resultVisible = false;
};

/**
 * Zeigt die Ergebnissanzeige
 * @public
 */
listRenderer.prototype.showResult = function(){
	const list = this.nosfield.util.toArray(this.result.children);
	if(list.length){
		this.nosfield.elements.result.style.display = 'block';
		this.nosfield.elements.result.style.position = 'fixed';
		
		this.setResultSize();
		this.setResultPosition();
		this.resultVisible = true;
	}else{
		this.hideResult();
	}
};

/**
 * Gibt zurück ob das Ergebniss sichtbar ist
 * @public
 * @return {bool}
 */
listRenderer.prototype.resultIsVisible = function(){
	return this.resultVisible;
};

/**
 * Setzt die Größe des Ergebnisses
 * @private
 */
listRenderer.prototype.setResultSize = function(){
	//vertical
	const resultOffset = this.nosfield.elements.result.getBoundingClientRect();
	const height = this.nosfield.elements.result.clientHeight;
	const offset = resultOffset.top + document.body.scrollTop;
	const clientHeight = Math.min(this.nosfield.options.maxHeight, document.documentElement.clientHeight);
	
	if(this.nosfield.elements.result.style['overflow-y'] === 'scroll' || (offset + height) > clientHeight){
		this.nosfield.elements.result.style['overflow-y'] = 'auto';
		this.nosfield.elements.result.style['max-height'] = `${this.nosfield.options.maxHeight}px`;
	}
	
	const scrollbarWidth = this.result.parentNode.offsetWidth - this.result.clientWidth;
	//horizontal
	if(this.nosfield.options.fullWidth){
		const resultWidth = document.documentElement.clientWidth - resultOffset.left - scrollbarWidth;
		this.result.style['min-width'] = `${resultWidth}px`;
	}else{
		this.result.style['min-width'] = `${this.nosfield.elements.view.getBoundingClientRect().width - scrollbarWidth}px`;
	}
};

/**
 * Positioniert das Ergebniss unter oder über der Eingabe
 *	macht auch eine horizontale Positionierung
 * @public
 */
listRenderer.prototype.setResultPosition = function(){
	const inputPosition = this.nosfield.elements.view.getBoundingClientRect();
	const MAX_HEIGHT = document.documentElement.clientHeight;
	
	const resultBlockInfo = this.nosfield.elements.result.getBoundingClientRect();
	
	//vertikale Positionierung
	if((inputPosition.top + resultBlockInfo.height + inputPosition.height) > MAX_HEIGHT){
		this.nosfield.elements.result.style.top = `${inputPosition.top - resultBlockInfo.height}px`;
	}else{
		this.nosfield.elements.result.style.top = `${inputPosition.top + inputPosition.height}px`;
	}
	
	//horiziontale positionierung
	this.nosfield.elements.result.style.left = `${inputPosition.right - resultBlockInfo.width}px`;
};

/**
 * Führt eine (silent-)Preload aus wenn nötig
 * @public
 */
listRenderer.prototype.preloadHandler = function(){
	const list = this.nosfield.util.toArray(this.result.children);
	const selected = this.nosfield.selector.getSelectedEl();
	
	const index = list.indexOf(selected);
	if(index > (list.length - (this.nosfield.options.pageLength / 2))){
		this.nosfield.searchComponent.silentPreload();
	}
	if(index > (list.length - 5)){
		this.nosfield.searchComponent.preload();
	}
};

/**
 * @param {HTMLElement} el
 * @returns {string}
 */
listRenderer.prototype.getViewResult = function(el){
	return el.innerText.trim();
};
