/**
 *
 * @constructor
 * @param {nosfield2} nosfield
 */
function restSearch(nosfield){
	/**
	 * @type {nosfield2}
	 */
	this.nosfield = nosfield;
	/**
	 * @type {?string}
	 */
	this.currentSearchId = null;
	/**
	 * @type {number}
	 */
	this.page = 1;
	/**
	 * @type {?string}
	 */
	this.lastSearchString = null;
	/**
	 * @type {object[]}
	 */
	this.cache = [];
	
	this.lastSearchTime = 0;
	this.last = null;
}

restSearch.prototype.init = function(){
	if(this.nosfield.options.selectedId){
		const request = {
			searchClass: this.nosfield.options.searchClass,
			selectedId: this.nosfield.options.selectedId,
			lang: this.nosfield.option.lang,
		};
		this.nosfield.options.restClient.ajax({
			endpoint: 'Mobile/Sys/nosfieldSelectedId',
			data: request,
			noLoader: true,
		}).then(res => {
			if(res.data.length === 1){
				const skip = this.nosfield.skipDoAfter;
				if(this.nosfield.options.selectedIdSkipDoAfter){
					this.nosfield.skipDoAfter = true;
				}
				
				this.nosfield.renderer.append(res.data[0]);
				this.nosfield.selector.selectFirst().getSelectedEl().click();
				this.nosfield.renderer.clearView();
				
				if(this.nosfield.options.selectedIdSkipDoAfter){
					this.nosfield.skipDoAfter = skip;
				}
			}
		});
		
	}
}

/**
 * Führt die eigentliche Suche aus
 * @private
 * @param {boolean} silent - soll der Abdunkler genutzt werden
 * @param {function} callback
 * @param {boolean}preloading
 * @param {boolean} force
 */
restSearch.prototype.genericSearch = function(silent, callback, preloading, force = false){
	const searchString = this.nosfield.util.normalize(this.nosfield.elements.view.value.trim());
	
	if(searchString.length < this.nosfield.options.minSearchTermLength){
		return;
	}
	
	if(!preloading && (!force && this.lastSearchString === searchString)){
		return;
	}
	
	this.lastSearchString = searchString;
	this.nosfield.searching = true;
	const searchId = this.generateSearchId();
	this.currentSearchId = searchId;
	
	const request = {
		search_fields: this.nosfield.options.searchFields,
		search_word: searchString,
		search_id: searchId,
		page: this.page,
		page_length: this.nosfield.options.pageLength,
		field_to_set: this.nosfield.options.fieldToSet,
		replaceFields: this.nosfield.options.replaceFields,
		searchClass: this.nosfield.options.searchClass,
		lang: this.nosfield.options.lang,
	};
	if(this.nosfield.options.noRelevance){
		request.no_relevance = true;
	}
	const currentSearchTime = new Date().getTime();
	this.lastSearchTime = currentSearchTime;
	
	this.nosfield.options.restClient.ajax({
		endpoint: 'Mobile/Sys/nosfieldSearch',
		data: request,
		noLoader: silent,
	}).then((result) => {
		if(result.data.length > 0){
			if(this.lastSearchTime === currentSearchTime){
				this.page++;
				if(result.data.length !== this.nosfield.options.pageLength){
					this.nosfield.continueSearch = false;
				}
				this.nosfield.searching = false;
				if(callback){
					callback(result.data);
				}
			}
		}else{
			this.nosfield.continueSearch = false;
			this.nosfield.searching = false;
			if(callback){
				callback(result.data);
			}
		}
	}).catch((e) => {
		this.nosfield.logger.error(e);
		this.nosfield.continueSearch = false;
		this.nosfield.searching = false;
		alert('nosfield restsearch error');
	});
}

/**
 * Suche nachdem etwas geschrieben wurde
 * @param {boolean} force
 * @public
 */
restSearch.prototype.search = function(force = false){
	this.nosfield.continueSearch = true;
	this.page = 1;
	this.genericSearch(false, (result) => {
		this.cache = [];
		if(result){//auch bei leerem ergebniss
			this.nosfield.renderer.replaceView(result);
			this.nosfield.renderer.showResult();
		}
	}, false, force);
};

/**
 * Läd weitere Datensätze nach bzw. holt sie aus dem Cache
 * @public
 */
restSearch.prototype.preload = function(){
	if(this.cache.length){
		this.nosfield.renderer.appendRows(this.cache);
		this.cache = [];
	}else{
		if(this.nosfield.continueSearch && !this.nosfield.searching){
			this.genericSearch(false, (result) => {
				if(result.length){
					this.nosfield.renderer.appendRows(result);
				}else{
					this.nosfield.continueSearch = false;
				}
			}, true);
		}
	}
};

/**
 * Befüllt den Cache mit weiteren Suchtreffern
 * @public
 */
restSearch.prototype.silentPreload = function(){
	if(!this.cache.length && this.nosfield.continueSearch && !this.nosfield.searching){
		this.nosfield.logger.log('info', 'silentPreload Page {page}', {
			page: this.page,
		});
		this.genericSearch(true, (result) => {
			if(result.length){
				this.cache = result;
				this.nosfield.renderer.preloadHandler();
			}else{
				this.nosfield.continueSearch = false;
			}
		}, true);
	}
};

/**
 * Generiert eine zufällige Id
 * @private
 * @returns {string}
 */
restSearch.prototype.generateSearchId = function(){
	return Math.floor(Math.random() * 1000000).toString();
};

/**
 * Holt abhängigkeiten zu anderen Nosfields
 * @private
 */
restSearch.prototype.buildRestrictions = function(){

};

/**
 * @public
 * @returns {restSearch}
 */
restSearch.prototype.resetLastSearchString = function(){
	this.lastSearchString = '';
	return this;
};