/**
 * @constructor
 * @param {nosfield2} nosfield
 */
function util(nosfield){
	/**
	 * @type {nosfield2}
	 */
	this.nosfield = nosfield;
}

/**
 * Checkt ob eine Variable ein Integer ist
 * @public
 * @param {*} val
 * @return {boolean}
 */
util.prototype.isInt = function(val){
	if(typeof val !== 'number'){
		val = parseInt(val);
	}
	
	return !isNaN(val) && isFinite(val) && Math.floor(val) === val;
};

/**
 * String Normalizer
 * @public
 * @param {string} param
 * @return {string}
 */
if(typeof "".normalize === 'function'){
	util.prototype.normalize = function(param){
		return param.normalize('NFC')
	}
}else{
	util.prototype.normalize = function(param){
		return param;
	};
}

/**
 *
 * @param {string} val
 * @returns {boolean}
 */
util.prototype.isNumberFormatLocale = function(val){
	if(Intl){
		try{
			// noinspection JSUnresolvedFunction
			const res = Intl.getCanonicalLocales(val);
			return res.length > 0;
		}catch(e){
			console.error(e);
			return false;
		}
	}else{
		return true;
	}
};

/**
 * @param {object}
 * @return {array}
 */
util.prototype.toArray = typeof Array.from === 'function'? Array.from : (obj) => Array.prototype.slice.call(obj);