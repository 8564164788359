/**
 * @constructor
 * @param {nosfield2} nosfield
 */
// eslint-disable-next-line no-redeclare
function event(nosfield){
	/**
	 * @type {nosfield2}
	 */
	this.nosfield = nosfield;
	
	/**
	 * @type {Object}
	 */
	this.eventCallbacks = {};
	
	this.eventNames = [
		'beforeDoAfter',//wird vor doAfter ausgeführt
		'doAfter',//wird nach auswahl ausgefürt
		'afterDoAfter',//wird nach doAfter ausgeführt
		'valueCleared',//hidden wert wurde gelöscht
		'valueChanged',//neuer wert wurde gesetzt
		'valueSet',//hidden wert wurde gesetzt
		'configButtonsCreated',//ein Button wurde erstellt
		'buttonClicked',//ein Button wurde geklickt
		'keydown',//keydown im Eingabeinput
		'keyup',//keyup im Eingabeinput
		'inputChanged',//eingabe hat sich geändert
		'closedByClick',//Suchergebniss ausgeblendet weil woanders geklickt wurde
		'resize',//window resize wurde ausgefürt - intern für Positionierung von Suchergebniss
		'requiredChanged',//input required wurde geändert
		'disabledChanged',//input disabled wurde geändert
		'hasRestrictionFor',//intern -
		'addRestriction',//intern - Restriction zu anderenm Nosfield wurde hinzugefügt
		'ready',//nosfield ist fertig initialisiert
		'destroy',//nosfield instanz wird zerstört
		'elementCreated',//einzelnes Suchergebniss Element wurde erstellt
		'viewReplaced',//Suchergebnisse wurden ersetzt
		'viewCleared',//Suchergebnisse wurden geleert
		'viewFocused',//Eingabeinput hat Fokus bekommen
		'viewBlured',//Eingabeinput hat Fokus verloren
		'viewClicked',//Eingabeinput wurde angeklickt
		'viewIsVisible',//Eingabeinput ist sichtbar geworden
		'viewIsNotVisible',//Eingabeinput ist nicht mehr sichtbar
		'selected',//suchergebniss wird selectiert (mouse hover oder tastatur)
		'multiRendererRemove',//Ein Element wurde aus der Mehrfachauswahl entfernt
		'show_and_focus_nosfield',
	];
}

event.prototype.init = function(){

};

/**
 * Trigger event
 * @param {string} eventName
 * @param {?object} evt
 * @param {?boolean} noLog
 * @return {event}
 */
event.prototype.emit = function(eventName, evt = {}, noLog = false){
	if(!this.eventNames.includes(eventName)){
		this.nosfield.logger.error('Event type {eventName} is not registered in event.eventNames', {eventName,});
	}
	
	if(typeof evt !== 'object'){
		evt = {};
	}
	
	const now = new Date().getTime();
	if(!noLog){
		this.nosfield.logger.log('info', 'emit Event {event} {timestamp}', {
			event: eventName,
			timestamp: now,
			evt: evt,
		});
	}
	
	if(eventName === 'doAfter'){
		this.emit('beforeDoAfter');
	}
	
	if(this.eventCallbacks[eventName]){
		evt.target = this.nosfield;
		evt.name = eventName;
		evt.timestamp = now;
		this.eventCallbacks[eventName].forEach(listener => {
			if(listener.preventDefault && evt.preventDefault){
				evt.preventDefault();
			}
			
			if(listener.stopPropagation && evt.stopPropagation){
				evt.stopPropagation();
			}
			
			if(listener.sync){
				listener.callback(evt);
			}else{
				setTimeout(() => {
					listener.callback(evt);
				});
			}
		});
	}
	
	if(eventName === 'doAfter'){
		this.emit('afterDoAfter');
	}
	
	return this;
};

/**
 * registriert ein Eventcallback
 * @public
 * @param {string} eventName
 * @param {function|object} callback
 * @param {?string} nosfieldId
 * @return {?Function}
 */
event.prototype.on = function(eventName, callback, nosfieldId = null){
	this.nosfield.logger.log('info', 'addEventListener {event} {timestamp}', {
		event: eventName,
		timestamp: new Date().getTime(),
	});
	
	if(typeof callback !== 'object'){
		callback = {
			callback: callback,
		};
	}else{
		if(!callback.callback){
			this.nosfield.logger.error('event for "{eventName}" without callback', Object.assign({eventName: eventName,}, callback));
			return null;
		}
	}
	callback.callback = callback.callback.bind(this.nosfield);
	if(!callback.id){
		callback.id = Math.floor(Math.random() * 100000 + 1).toString();
	}
	
	if(eventName === 'ready' && this.nosfield.ready){
		setTimeout(() => {
			const evt = {
				target: this.nosfield,
				name: eventName,
				timestamp: +(new Date()),
			};
			
			callback.callback(evt);
		});
	}
	
	let unbind;
	
	if(nosfieldId){
		const externalNosfield = nosfield2.get(nosfieldId);
		if(externalNosfield){
			unbind = externalNosfield.event.add(eventName, callback);
		}
	}else{
		unbind = this.add(eventName, callback);
	}
	return unbind;
}
;


/**
 * Sollte nur von event.on() genutzt werden
 * @public
 * @param {string} eventName
 * @param {object} callback
 * @return {function}
 */
event.prototype.add = function(eventName, callback){
	if(!this.eventCallbacks[eventName]){
		this.eventCallbacks[eventName] = [];
	}
	/**
	 * Off Funktion um Eventlistener wieder zu löschen
	 * @return {boolean}
	 */
	const unbind = () => {
		for(let i in this.eventCallbacks[eventName]){
			if(this.eventCallbacks[eventName].hasOwnProperty(i) && this.eventCallbacks[eventName][i] === callback){
				delete this.eventCallbacks[eventName][i];
				return true;
			}
		}
		return false;
	};
	
	callback.unbind = unbind;
	
	
	this.eventCallbacks[eventName].push(callback);
	
	
	return unbind;
};

/**
 * @public
 * @param eventName
 * @return {event}
 */
event.prototype.clearEvent = function(eventName){
	this.nosfield.logger.log('info', 'clear Events for {eventName}', {eventName: eventName,});
	this.eventCallbacks[eventName] = [];
	return this;
};

/**
 * @public
 * @param {string} eventName
 * @param {string} id
 * @return {event}
 */
event.prototype.clearById = function(eventName, id){
	if(this.eventCallbacks[eventName]){
		this.eventCallbacks[eventName].forEach(evt => {
			if(evt.id === id){
				evt.unbind();
			}
		});
	}
	
	return this;
};