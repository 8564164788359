/**
 * @typedef {string} logLevels
 */

/**
 * @constructor
 * @param {nosfield2} nosfield
 */
function logger(nosfield){
	/**
	 * @type {nosfield2}
	 */
	this.nosfield = nosfield;
	/**
	 * @type {Array}
	 */
	this.logStack = [];
}

/**
 * @enum {logLevels}
 * @type {{debug: string, critical: string, alert: string, emergency: string, warning: string, error: string, notice: string, info: string}}
 */
logger.prototype.levels = {
	emergency: 'emergency',
	alert: 'alert',
	critical: 'critical',
	error: 'error',
	warning: 'warning',
	notice: 'notice',
	info: 'info',
	debug: 'debug',
};

/**
 * @public
 * @param {logLevels} level
 * @param {string} message
 * @param {object} context
 */
logger.prototype.log = function(level, message, context = {}){
	if(typeof this[level] === 'function' && level !== 'log'){
		this[level](message, context);
	}else{
		this.error('unknown loglevel: {level}', Object.assign(context, {level: level,}));
	}
};

/**
 * @public
 * @param {string} message
 * @param {object} context
 */
logger.prototype.emergency = function(message, context){
	context = context || {};
	message = this.formatString(message, context);
	this.logStack.push({
		level: 'emergency',
		message: message,
		context: context,
	});
	console.error('Nosfield emergency:', message, context, this.nosfield);
};

/**
 * @public
 * @param {string} message
 * @param {object} context
 */
logger.prototype.alert = function(message, context){
	context = context || {};
	message = this.formatString(message, context);
	this.logStack.push({
		level: 'alert',
		message: message,
		context: context,
	});
	console.error('Nosfield alert:', message, context, this.nosfield);
};

/**
 * @public
 * @param {string} message
 * @param {object} context
 */
logger.prototype.critical = function(message, context){
	context = context || {};
	message = this.formatString(message, context);
	this.logStack.push({
		level: 'critical',
		message: message,
		context: context,
	});
	console.error('Nosfield critical error:', message, context, this.nosfield);
};

/**
 * @public
 * @param {string} message
 * @param {object} context
 */
logger.prototype.error = function(message, context){
	context = context || {};
	message = this.formatString(message, context);
	this.logStack.push({
		level: 'error',
		message: message,
		context: context,
	});
	console.error('Nosfield error:', message, context, this.nosfield);
};

/**
 * @public
 * @param {string} message
 * @param {object} context
 */
logger.prototype.warning = function(message, context){
	context = context || {};
	message = this.formatString(message, context);
	this.logStack.push({
		level: 'warning',
		message: message,
		context: context,
	});

	console.warn('Nosfield warn', message, context, this.nosfield);
};

/**
 * @public
 * @param {string} message
 * @param {object} context
 */
logger.prototype.notice = function(message, context){
	context = context || {};
	message = this.formatString(message, context);
	this.logStack.push({
		level: 'notice',
		message: message,
		context: context,
	});
};

/**
 * @public
 * @param {string} message
 * @param {object} context
 */
logger.prototype.info = function(message, context){
	context = context || {};
	message = this.formatString(message, context);
	this.logStack.push({
		level: 'info',
		message: message,
		context: context,
	});
};

/**
 * @public
 * @param {string} message
 * @param {object} context
 */
logger.prototype.debug = function(message, context){
	context = context || {};
	message = this.formatString(message, context);
	this.logStack.push({
		level: 'debug',
		message: message,
		context: context,
	});
	if(this.nosfield.options.debug){
		console.info('Nosfield Debug', message, context, this.nosfield);
	}
};
/**
 *
 * @private
 * @param {string} message
 * @param {object} context
 * @returns {string}
 */
logger.prototype.formatString = function(message, context){
	for(let i in context){
		message = message.replace(`{${i}}`, context[i]);
	}

	return message;
};

/**
 * @public
 * @param {string} level
 * @return {array}
 */
logger.prototype.getLogs = function(level){
	const res = [];
	this.logStack.forEach(log => {
		if(log.level === level){
			res.push(log);
		}
	});
	return res;
};