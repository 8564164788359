/**
 * @param {nosfield2} nosfield
 * @constructor
 */
function ResultRendererInput(nosfield){
	this.name = "ResultRendererInput";
	
	this.nosfield = nosfield;
	
	this.view = null;
	this.hidden = null;
	
}

/**
 * @public
 */
ResultRendererInput.prototype.init = function(){
	this.view = this.nosfield.elements.view;
	this.hidden = this.nosfield.elements.hidden;
};

/**
 * @public
 * @param {object} obj
 * @param {?string} obj.view
 * @param {?string} obj.hidden
 * @returns {ResultRendererInput}
 */
ResultRendererInput.prototype.setValue = function(obj){
	const view = obj.view || '';
	const hidden = obj.hidden || '';
	
	this.view.value = view;
	this.hidden.value = hidden;
	
	if(view === '' && hidden === ''){
		this.nosfield.options.onClear(this.nosfield);
		this.nosfield.event.emit('valueCleared', {nosfieldId: this.id,});
	}else{
		if(view !== '' && hidden !== ''){
			this.nosfield.event.emit('valueSet', {nosfieldId: this.id, view: view, hidden: hidden,});
		}
	}
	
	return this;
};

/**
 * @public
 * @returns {{view: string, hidden: string}}
 */
ResultRendererInput.prototype.getValue = function(){
	return {
		view: this.view.value.toString(),
		hidden: this.hidden.value.toString(),
	};
};

/**
 * @public
 * @param {{view:string,obj:string}} obj
 * @returns {ResultRendererInput}
 */
ResultRendererInput.prototype.addValue = function(obj){
	return this.setValue(obj);
};

ResultRendererInput.prototype.clear = function(){
	this.setValue({
		view: '',
		hidden: '',
	});
};