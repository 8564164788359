/**
 * nosfield2 selector Konstruktor
 * @constructor
 * @param {nosfield2} nosfield
 */
function selector(nosfield){
	/**
	 * @type {nosfield2}
	 */
	this.nosfield = nosfield;
}

/**
 * Aus konsistenzgründen
 * @public
 */
selector.prototype.init = function(){

};

/**
 * Liefert das momentan ausgewählte Element zurück
 * @public
 * @return {?HTMLElement}
 */
selector.prototype.getSelectedEl = function(){
	return this.nosfield.elements.result.querySelector(`.${this.nosfield.options.selectedRowClass}`);
};

/**
 * Deselektiert aktuelle Selektierung wenn vorhanden
 * @public
 * @return {selector}
 */
selector.prototype.deselect = function(){
	const row = this.getSelectedEl();
	
	if(row){
		row.className = row.className.replace(this.nosfield.options.selectedRowClass, '');
		this.nosfield.options.onDeselect(this.nosfield, row.dataset.id);
	}
	return this;
};

/**
 * Selektiert übergebenes Element und Scrollt zu diesem Element, wenn parameter angegeben wird
 * @public
 * @param {object} el - HTML element (nosfield Ergebnisszeile)
 * @param {boolean} scroll - soll zum Element gescrollt werden
 * @return {selector}
 */
selector.prototype.selectEl = function(el, scroll = false){
	if(el){
		el = el.closest(`.${this.nosfield.options.rowClass}`);
		if(el){
			this.deselect();
			el.className += ` ${this.nosfield.options.selectedRowClass}`;
			this.nosfield.event.emit('selected', {
				id: el.dataset.id,
				scroll: !!scroll,
			});
			//this.nosfield.options.onSelect(this.nosfield, el.dataset.id);
			if(scroll){
				el.scrollIntoView({
					behavior: this.nosfield.options.scrollBehaviour,
					block: this.nosfield.options.scrollBlock,
				});
			}
			this.nosfield.renderer.preloadHandler();
		}
	}
	return this;
};

/**
 * Selektiert erstes Ergebniss, wenn vorhanden
 * @public
 * @return {selector}
 */
selector.prototype.selectFirst = function(){
	const firstEl = this.nosfield.elements.result.querySelector(`.${this.nosfield.options.rowClass}`);
	if(firstEl){
		this.selectEl(firstEl, true);
	}
	return this;
};

/**
 * Selektiert letztes Ergebniss, wenn vorhanden
 * @public
 * @return {selector}
 */
selector.prototype.selectLast = function(){
	const elements = this.nosfield.elements.result.querySelectorAll(`.${this.nosfield.options.rowClass}`);
	if(elements.length){
		this.selectEl(elements[elements.length - 1], true);
	}
	return this;
};

/**
 * Selektiert nächstes Ergebniss
 * @public
 * @param {int} skip - wie viele Ergebnisse sollen übersprungen werden
 * @return {selector}
 */
selector.prototype.selectNext = function(skip){
	const selectedEl = this.getSelectedEl();
	if(!selectedEl){
		this.selectFirst();
		return this;
	}
	
	const rows = this.nosfield.elements.result.querySelectorAll(`.${this.nosfield.options.rowClass}`);
	let useThis = false;
	
	for(let i = 0; i < rows.length; i++){
		if(useThis){
			this.selectEl(rows[i], true);
			return this;
		}else{
			if(selectedEl === rows[i]){
				useThis = true;
				i += skip;
			}
		}
	}
	return this.selectLast();
	
};

/**
 * Selektiert vorheriges Ergebniss
 * @public
 * @constructor
 * @param {int} skip - wie viele Ergebnisse sollen übersprungen werden
 * @return {selector}
 */
selector.prototype.selectPrevious = function(skip){
	const selectedEl = this.getSelectedEl();
	if(!selectedEl){
		this.selectLast();
		return this;
	}
	
	const rows = this.nosfield.elements.result.querySelectorAll(`.${this.nosfield.options.rowClass}`);
	let useThis = false;
	
	for(let i = rows.length - 1; i >= 0; i--){
		if(useThis){
			this.selectEl(rows[i], true);
			return this;
		}else{
			if(selectedEl === rows[i]){
				useThis = true;
				i -= skip;
			}
		}
	}
	return this.selectFirst();
};

/**
 * Selektiert ein Ergebniss anhand der Datenbank Id
 * @public
 * @param {string} id
 * @param {bool} scroll
 * @return {selector}
 */
selector.prototype.selectById = function(id, scroll){
	const el = this.nosfield.elements.result.querySelector(`[data-id="${id}"]`);
	if(el){
		this.selectEl(el, scroll);
	}
	return this;
};

/**
 * @public
 * @return {Boolean}
 */
selector.prototype.fistIsSelected = function(){
	const selectedEl = this.getSelectedEl();
	if(!selectedEl){
		return false;
	}
	
	const rows = this.nosfield.util.toArray(this.nosfield.elements.result.querySelectorAll(`.${this.nosfield.options.rowClass}`));
	
	return (rows.indexOf(selectedEl) === 0);
};

/**
 * @public
 * @return {Boolean}
 */
selector.prototype.lastIsSelected = function(){
	const selectedEl = this.getSelectedEl();
	if(!selectedEl){
		return false;
	}
	
	const rows = this.nosfield.util.toArray(this.nosfield.elements.result.querySelectorAll(`.${this.nosfield.options.rowClass}`));
	
	return (rows.indexOf(selectedEl) === rows.length - 1);
};

/**
 * @public
 * @return {Number}
 */
selector.prototype.getResultCount = function(){
	const rows = this.nosfield.elements.result.querySelectorAll(`.${this.nosfield.options.rowClass}`);
	
	return this.nosfield.util.toArray(rows).length;
};