/**
 * nosfield2 tableRenderer Konstruktor
 * @constructor
 * @param {nosfield2} nosfield
 */
function tableRenderer(nosfield){
	/**
	 * @type {nosfield2}
	 */
	this.nosfield = nosfield;
	/**
	 *
	 * @type {object|null}
	 */
	this.result = null;
	/**
	 * @type {boolean}
	 */
	this.resultVisible = false;
	if(Intl && Intl.NumberFormat){
		/**
		 * @type {Intl.NumberFormat}
		 */
		this.numberFormatter = new Intl.NumberFormat(this.nosfield.options.numberFormatLocale, {
			style: 'decimal',
			useGrouping: true,
			minimumFractionDigits: this.nosfield.options.numberFormatDecimals,
			maximumFractionDigits: this.nosfield.options.numberFormatDecimals,
		});
	}else{
		/**
		 * @type {{format: (function(*): *)}}
		 */
		this.numberFormatter = {
			format: a => a,
		};
	}
}

/**
 * Initialisation - erstellt result Table und wheel event
 * @public
 */
tableRenderer.prototype.init = function(){
	//init result table
	const el = document.createElement('table');
	el.className = 'nosfieldResultTable';
	
	this.result = el;
	el.addEventListener('wheel', (evt) => {
		if(evt.deltaY > 0){
			if(this.nosfield.continueSearch && !this.nosfield.searchComponent.cache.length){
				this.nosfield.searchComponent.silentPreload();
			}
			
			const list = el.querySelectorAll('tr');
			const last = list[list.length - 1];
			
			// noinspection JSUnresolvedFunction
			const tablePos = el.parentNode.getBoundingClientRect();
			const lastPos = last.getBoundingClientRect();
			
			
			const top = tablePos.top + tablePos.height + (1.5 * lastPos.height);
			
			if(lastPos.top < top){
				this.nosfield.searchComponent.preload();
			}
		}
	}, {passive: true,});
	
	window.addEventListener('resize', () => {
		if(this.resultIsVisible()){
			this.setResultSize();
			this.setResultPosition();
		}
	});
	
	this.nosfield.elements.result.appendChild(el);
};

/**
 * Leert das Ergebniss
 * @public
 * @return {tableRenderer}
 */
tableRenderer.prototype.clearView = function(){
	this.result.innerHTML = '';
	this.nosfield.event.emit('viewCleared');
	
	return this;
};

/**
 * Fügt eine Zeile ans Ergebniss an
 * @private
 * @param {object} row
 * @return {object} - das erzeugte HTML Element
 */
tableRenderer.prototype.append = function(row){
	const el = this.createElement(row);
	this.result.appendChild(el);
	
	return el;
};

/**
 * Fügt mehrere Zeile ans Ergebniss an
 * @public
 * @param {object[]} rows
 * @return {tableRenderer}
 */
tableRenderer.prototype.appendRows = function(rows){
	for(let i in rows){
		this.append(rows[i]);
	}
	return this;
};

/**
 * Ersetzt die View komplett - behält Selektion bei wenn möglich
 * @public
 * @param {object[]} elements
 * @return {tableRenderer}
 */
tableRenderer.prototype.replaceView = function(elements){
	this.clearView();
	
	this.appendRows(elements);
	
	this.nosfield.selector.selectFirst();
	//Firefox mag es nicht wenn man sofort scrollt
	setTimeout(() => {
		this.result.parentNode.scrollTo(0, 0);
	});
	
	this.nosfield.event.emit('viewReplaced');
	
	return this;
};

/**
 * Erstellt eine ein tr Element für das Ergebniss
 * @private
 * @param {object} row
 * @return {object} - Das tr Element
 */
tableRenderer.prototype.createElement = function(row){
	const tr = document.createElement('tr');
	tr.className = `xRow ${this.nosfield.options.rowClass}`;
	
	let displayFields = this.nosfield.options.displayFields;
	if(displayFields.length === 0){
		displayFields = Object.keys(row);
	}
	
	const conditionalStyle = this.nosfield.conditionalStyle.checkRow(row);
	
	displayFields.forEach((i) => {
		if(i === this.nosfield.options.fieldToSet && this.nosfield.options.displayFields.indexOf(i) === -1){
			return;
		}
		let td = document.createElement('td');
		td.className = this.nosfield.options.cellClass;
		td.dataset.field_name = i.toString();
		
		//wenn in options ein Feldtyp definiert ist gibt es ein gesondertes Rendering
		if(this.nosfield.options.fieldKinds[i] !== undefined){
			this.renderFieldKind(td, this.nosfield.options.fieldKinds[i], row[i], row);
		}else{
			td.innerHTML = row[i] || '';
		}
		
		for(let styleObj = null, k = 0; k < conditionalStyle.length; k++){
			styleObj = conditionalStyle[k];
			if(typeof (styleObj) === 'string'){
				tr.classList.add(styleObj);
			}else{
				for(let j in styleObj){
					if(styleObj.hasOwnProperty(j)){
						td.style[j] = styleObj[j];
					}
				}
			}
		}
		
		
		tr.appendChild(td);
	});
	
	tr.dataset.nosfieldId = this.nosfield.id;
	tr.dataset.id = row[this.nosfield.options.fieldToSet];
	tr.dataset.row = JSON.stringify(row);
	
	tr.onclick = (evt) => {
		this.nosfield.handleClick(evt);
	};
	
	tr.onmousemove = (evt) => {
		this.nosfield.selector.selectEl(evt.target);
	};
	this.nosfield.event.emit('elementCreated', {el: tr,}, true);
	return tr;
};

/**
 * Befüllt ein spezial td mit Daten wenn es in nosfield.options.fieldKinds definiert ist
 * @private
 * @param {object} el - das td Element
 * @param {object} data - der eintrag in nosfield.options.fieldKinds
 * @param {string} content - der Inhalt des Feldes
 * @param {object} row - Das Datenobject der Zeile
 * @return {object} - das td Element
 */
tableRenderer.prototype.renderFieldKind = function(el, data, content, row){
	data.options = data.options || {};
	switch(data.kind){
		case 'img':
		case 'image':
			const img = document.createElement('img');
			img.src = content;
			img.style.width = data.options.width || 'auto';
			img.style.height = data.options.height || 'auto';
			
			el.appendChild(img);
			break;
		
		case 'a':
			const a = document.createElement('a');
			let href = data.options.href || content;
			
			a.innerText = content;
			
			for(let i in row){
				href = href.replace(`[replace][${i}]`, row[i]);
			}
			
			a.href = href;
			a.target = '_blank';
			
			a.onclick = function(evt){
				//verhindert, dass zeile ausgewählt wird
				evt.stopPropagation();
			};
			
			el.appendChild(a);
			break;
		
		case 'float':
		case 'double':
			const newValue = parseFloat(content);
			if(!isNaN(newValue)){
				content = newValue.toString();
			}
			
			el.innerText = this.numberFormatter.format(content);
			break;
	}
	
	return el;
};

/**
 * Versteckt die Ergebnissanzeige
 * @public
 */
tableRenderer.prototype.hideResult = function(){
	this.nosfield.elements.result.style.display = 'none';
	this.nosfield.elements.result.style.position = 'relative';
	this.resultVisible = false;
};

/**
 * Zeigt die Ergebnissanzeige
 * @public
 */
tableRenderer.prototype.showResult = function(){
	const list = this.nosfield.util.toArray(this.result.children);
	if(list.length){
		this.nosfield.elements.result.style.display = 'block';
		this.nosfield.elements.result.style.position = 'fixed';
		
		this.setResultSize();
		this.setResultPosition();
		this.resultVisible = true;
	}else{
		this.hideResult();
	}
};

/**
 * Gibt zurück ob das Ergebniss sichtbar ist
 * @public
 * @return {boolean}
 */
tableRenderer.prototype.resultIsVisible = function(){
	return this.resultVisible;
};

/**
 * Setzt die Größe des Ergebnisses
 * @private
 */
tableRenderer.prototype.setResultSize = function(){
	//vertical
	const resultOffset = this.nosfield.elements.result.getBoundingClientRect();
	const height = this.nosfield.elements.result.clientHeight;
	const offset = resultOffset.top + document.body.scrollTop;
	
	const inputPosition = this.nosfield.elements.view.getBoundingClientRect();
	const clientHeight = Math.min(
		Math.max(
			document.documentElement.clientHeight - (document.documentElement.clientHeight - inputPosition.top) - this.nosfield.options.resultVMargin,
			document.documentElement.clientHeight - inputPosition.top - inputPosition.height - this.nosfield.options.resultVMargin
		),
		this.nosfield.options.maxHeight
	);
	
	if(this.nosfield.elements.result.style['overflow-y'] === 'scroll' || (offset + height) > clientHeight){
		this.nosfield.elements.result.style['overflow-y'] = 'scroll';
		this.nosfield.elements.result.style['max-height'] = `${clientHeight}px`;
	}
	
	const scrollbarWidth = this.result.parentNode.offsetWidth - this.result.clientWidth;
	//horizontal
	if(this.nosfield.options.fullWidth){
		const resultWidth = document.documentElement.clientWidth - resultOffset.left - scrollbarWidth;
		this.result.style['min-width'] = `${resultWidth}px`;
	}else{
		this.result.style['min-width'] = `${inputPosition.width - scrollbarWidth}px`;
		if(this.nosfield.options.forceWidthToInput){
			this.result.style['max-width'] = `${inputPosition.width - scrollbarWidth}px`;
		}
	}
};

/**
 * Positioniert das Ergebniss unter oder über der Eingabe
 *	macht auch eine horizontale Positionierung
 * @public
 */
tableRenderer.prototype.setResultPosition = function(){
	const inputPosition = this.nosfield.elements.view.getBoundingClientRect();
	const MAX_HEIGHT = document.documentElement.clientHeight;
	
	const resultBlockInfo = this.nosfield.elements.result.getBoundingClientRect();
	
	//vertikale Positionierung
	if(!this.nosfield.options.forceResultBelow && (inputPosition.top + resultBlockInfo.height + inputPosition.height) > MAX_HEIGHT){
		this.nosfield.elements.result.style.top = `${inputPosition.top - resultBlockInfo.height}px`;
	}else{
		this.nosfield.elements.result.style.top = `${inputPosition.top + inputPosition.height}px`;
	}
	
	//horiziontale positionierung
	const MAX_WIDTH = document.documentElement.clientWidth;
	if((inputPosition.left + resultBlockInfo.width) > MAX_WIDTH){
		const overflow = inputPosition.left + resultBlockInfo.width - MAX_WIDTH;
		if(overflow > 0){
			this.nosfield.elements.result.style.left = `${inputPosition.left - overflow}px`;
		}else{
			this.nosfield.elements.result.style.left = '0px';
		}
	}else{
		this.nosfield.elements.result.style.left = `${inputPosition.left}px`;
	}
};


/**
 * Führt eine (silent-)Preload aus wenn nötig
 * @public
 */
tableRenderer.prototype.preloadHandler = function(){
	const list = this.nosfield.util.toArray(this.result.children);
	if(list.length % this.nosfield.options.pageLength === 0){
		const selected = this.nosfield.selector.getSelectedEl();
		
		const index = list.indexOf(selected);
		
		if(index > (list.length - (this.nosfield.options.pageLength / 2))){
			this.nosfield.searchComponent.silentPreload();
		}
		if(index > (list.length - 5)){
			this.nosfield.searchComponent.preload();
		}
	}
};

/**
 * @public
 * @param {HTMLElement} el
 * @returns {string}
 */
tableRenderer.prototype.getViewResult = function(el){
	let view = '';
	const tds = el.querySelectorAll('td');
	for(let i = 0; i < tds.length; i++){
		if(tds[i].innerText){
			view += ' ' + tds[i].innerText.replace('&quot;', '"');
		}
	}
	
	return view.trim();
};
