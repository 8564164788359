/*
	condition = {
		op: '=',
		field: 'modul_erp_article__article_status',
		value: '1',
		style: {
			color: 'green',
		},
		else: {
			color: red,
		},
	}
	
	condition = {
		op: '&&',
		style: {
			'font-weight': 'bold'
		},
		value: [
			{
				op: '=',
				value: '1',
				field: 'modul_erp_article__article_status,
			},
			{
				op: '>',
				value: 0,
				field: 'modul_erp_article__main_stock',
			},
		]
	}
*/

/**
 * conditionalStyle Kosntruktor
 *	Hilfsobjekt für komplexe Vergleichslogik
 * @param {object} nosfield
 * @constructor
 */
function conditionalStyle(nosfield){
	this.nosfield = nosfield;
}

/**
 * Initialisation - macht aktuell nichts
 * @public
 */
conditionalStyle.prototype.init = function(){
	
};

/**
 * Führt alle Vergleiche in den nosfield2.options an einer Zeile aus
 * @public
 * @param {object} row
 * @return {object}  Hashmap der key: css Eigenschaft; value: Wert der Eigenschaft
 */
conditionalStyle.prototype.checkRow = function(row){
	const styles = [];
	this.nosfield.options.styleConditions.forEach(condition => {
		if((condition.style || condition.class) && this.checkCondition(row, condition)){
			styles.push(condition.style || condition.class);
		}else{
			if(condition.else){
				styles.push(condition.else);
			}
		}
	});
	
	return styles;
};

/**
 * Führt eine Bedingung aus
 * @private
 * @param {object} row
 * @param {object} condition Beispiele am Anfang der Datei
 * @return {boolean}
 */
conditionalStyle.prototype.checkCondition = function(row, condition){
	let result = false;
	if(condition.op && condition.field && row[condition.field]){
		switch(condition.op){
			case '=':
				// noinspection EqualityComparisonWithCoercionJS
				if(row[condition.field] == condition.value){
					result = true;
				}
				break;
			
			case '!=':
				// noinspection EqualityComparisonWithCoercionJS
				if(row[condition.field] != condition.value){
					result = true;
				}
				break;
			
			case '>':
				if(row[condition.field] > condition.value){
					result = true;
				}
				break;
			
			case '>=':
				if(row[condition.field] >= condition.value){
					result = true;
				}
				break;
			
			case '<':
				if(row[condition.field] < condition.value){
					result = true;
				}
				break;
			
			case '<=':
				if(row[condition.field] <= condition.value){
					result = true;
				}
				break;
			
			case '&&':
			case 'and':
				let subResultAnd = false;
				for(let i in condition.value){
					subResultAnd = this.checkCondition(row, condition.value[i]);
					if(!subResultAnd){
						break;
					}
				}
				
				result = subResultAnd;
				break;
			
			case '||':
			case 'or':
				let subResultOr = false;
				for(let j in condition.value){
					subResultOr = this.checkCondition(row, condition.value[j]);
					if(subResultOr){
						break;
					}
				}
				
				result = subResultOr;
				break;
			
			case '^':
			case 'xor':
				let allreadyTrue = false;
				let subResultXor = false;
				for(let i in condition.value){
					subResultXor = this.checkCondition(row, condition.value[i]);
					
					if(allreadyTrue && subResultXor){
						subResultXor = false;
						break;
					}else{
						if(subResultXor){
							allreadyTrue = true;
						}
					}
				}
				
				result = subResultXor;
				break;
			case 'regex':
				const reg = new RegExp(condition.value);
				if(row[condition.field].toString().match(reg)){
					result = true;
				}
				break;
			
			case 'function':
				if(typeof (condition.value) == 'function'){
					result = condition.value(row[condition.field]);
				}
				break;
			
			case 'in':
				result = condition.value.includes(row[condition.field]);
				
				break;
			case 'contains':
				result = row[condition.field].contains(condition.value);
				break;
			default:
				result = false;
			
		}
	}
	
	return result;
};
